import './App.css';
import comingSoonVideo from './assets/comingSoon_black.mp4'
import facebookIcon from './assets/icons8-facebook.svg'
import instagramIcon from './assets/icons8-instagram(1).svg'
import linkedinIcon from './assets/icons8-linkedin.svg'
import twitterIcon from './assets/icons8-twitter.svg'
import youtubeIcon from './assets/icons8-youtube.svg'

function App() {
  return (
    <div className="App">
      <div id={"comingSoonContainer"}>
        <video id={"comingSoonVideo"} src={comingSoonVideo} autoPlay loop muted/>
        <h1 id={"comingSoonHeader"}>New Site Under Construction Nov 2024</h1>
        <h5 id={"comingSoonSubHeader"}>For Inquires about our products and services email us at  contact@paeon.co :)</h5>
      </div>
    </div>
  );
}

export default App;
